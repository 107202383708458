/*
   Base Element
   ========================================================================== */
html {
  height: 100%;  
}

body {
  overflow-x:hidden;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

.hover {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

*:focus {
  outline:none;
}

button:focus, input:focus, textarea:focus, select:focus {
  outline: initial;
}

//
// Typography
// --------------------------------------------------


a {
  @include transition(all 0.2s ease-out);
}

// a:not(.btn):visited {
//   color: lighten($text-color, 10%);
// }
// 
.overflow-hidden {
  overflow: hidden !important;
}

// Typography CSS helper classes - put these into your HTML
.no-underline {
  text-decoration: none !important;
}

.text-small {
  font-size: em($font-size-small);
}

.text-xs, .text-xsmall {
  font-size: em($font-size-xs);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none !important;
}

.text-bold {
  font-weight: bold;
}
.font-weight-semibold, .text-semibold {
  font-weight: 600;
}

.text-black, .text--black { color: $black !important; }
.text--white { color: #fff; }

ul.list-dashed {
  @include list-dashed;
}

.text-center-sm {
  @include min($sm) {
    text-align: center;
  }
}

.fa {
  vertical-align: middle;
}

a[href^="tel"]{ color:inherit; text-decoration:none; }

[class^="-icon"], [class^="icon-"] {
  &:hover, &:focus {
    text-decoration: none !important;
  }
}

ol {
  list-style-position: inside;
  padding-left: 0;
}