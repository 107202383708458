//
// VA Helpful Base Variables
// --------------------------------------------------


// ** Extra Media query variables.


$screen-tiny: 320px;
$mobile-tiny: 320px;
$android:     360px; // Android phones - HTC, Droid etc
$i7:          375px; // iPhone 7
$i5-ls:       568px;
$kindle:      600px; // Kindles, Asus, Nexus Tabs
$tablet-sm:   600px;
$mobile-lg:   640px;
$i7-ls:       667px; // iPhone7
$mobile-xlg:  720px; // also MS SUrface
$surface:     720px; // MS SUrface
$motox:       720px;
$ipad:        768px;


$macbook:  1150px; // This is to handle the widths for Macbook 13" displays 
$xlg:      1380px;
$xxlg:     1600px;
$xxxlg:    1920px;

// Bootstrap's sizes
$xs:       $screen-xs;
$sm:       $screen-sm;
$md:       $screen-md;
$lg:       $screen-lg;


// Extra container classes
$container-xlarge-desktop:      $xlg !default;
// ** For `$xlg-min` and up.
$container-xlg:                 $container-xlarge-desktop !default;

// Vertical rhythm - vertical gaps
$vgap: 10px;//floor($line-height-computed/2);

$vgap2: $vgap * 2; // line height (20px typically)
$vgap3: $vgap * 3; // 
$vgap4: $vgap * 4; // 
$vgap5: $vgap * 5; // 
$vgap6: $vgap * 6; // 
$vgap7: $vgap * 7; // 
$vgap8: $vgap * 8; // 
$vgap9: $vgap * 9; // 
$vgap10: $vgap * 10;

// Horizontal rhythm (...not being dirty...) - horizontal gaps
$gap: $grid-gutter-width / 2; // (15px typically)
$gap2: $gap * 2;
$gap3: $gap * 3;
$gap4: $gap * 4;
$gap5: $gap * 5;
$gap6: $gap * 6;
$gap7: $gap * 7;
$gap8: $gap * 8;
$gap9: $gap * 9;
$gap10: $gap * 10;

$vgaps: (
  0: 0, 
  h: $vgap/2,
  1: $vgap, 
  1h: $gap,
  2: $vgap2, 
  3: $vgap3,
  4: $vgap4,
  5: $vgap5,
  6: $vgap6,
  7: $vgap7,
  8: $vgap8,
  10: $vgap10
);

$gaps: (
  0: 0,
  h: $gap/2, 
  1: $gap, 
  2: $gap2
);

@each $i, $px in $vgaps {
  .mt-#{$i} {
    margin-top: $px !important;;
  }
  .mb-#{$i} {
    margin-bottom: $px !important;;
  }  
  .my-#{$i} {
    margin-top: $px !important;;
    margin-bottom:$px !important;;
  }  
  .pt-#{$i} {
    padding-top: $px !important;;
  }
  .pb-#{$i} {
    padding-bottom: $px !important;;
  }  
  .py-#{$i} {
    padding-top: $px !important;;
    padding-bottom:$px !important;;
  }
}

@each $i, $g in $gaps {
  .mr-#{$i} {
    margin-right: $g !important;
  }
  .ml-#{$i} {
    margin-left: $g !important;;
  }  
  .mx-#{$i} {
    margin-left: $g !important;;
    margin-right: $g !important;;
  }
}

// ** Extra hidden/visibility classes for screen size xxs (320px)
// Duplicate code of Bootstrap CSS for other screen sizes.
.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block {
  display: none !important;
}

// @media (max-width: $screen-xxs-max) {
//   //@include responsive-visibility('.visible-xxs');
// }
.visible-xxs-block {
  @media only screen and (max-width: ($mobile-tiny - 1)) {
    display: block !important;
  }
}
.visible-xxs-inline {
  @media only screen and (max-width: ($mobile-tiny - 1)) {
    display: inline !important;
  }
}
.visible-xxs-inline-block {
  @media only screen and (max-width: ($mobile-tiny - 1)) {
    display: inline-block !important;
  }
}

//
// Utilities for common `display` values
//
.d-none         { display: none !important; }
.d-inline       { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block        { display: block !important; }
.d-table        { display: table !important; }
.d-table-cell   { display: table-cell !important; }
.d-flex         { display: flex !important; }
.d-inline-flex  { display: inline-flex !important; }

.pos-rel {
  position: relative;
}

.valign-middle {
  vertical-align: middle;
}
