// This comes from the parent Bootstrap theme downloaded from Drupal
// feel free to use as little or as much as you like

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

body.cke_editable {
  padding: 20px !important;
}

html {
  &.overlay-open .navbar-fixed-top {
    z-index: 400;
  }
  &.js {
    // Collapsible fieldsets.
    fieldset.collapsed {
      border-left-width: 1px;
      border-right-width: 1px;
      height: auto;
    }
    // Throbbers.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete (fallback throbber, no icon).
    .autocomplete-throbber {
      background-position: 100% 2px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 15px;
      margin: 2px 0 0 2px;
      width: 15px;
    }
    .autocomplete-throbber.throbbing {
      background-position: 100% -18px;
    }
  }
}

// Adjust z-index of core components.
#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 1500;
}
#toolbar {
  z-index: 1600;
}
// Adjust z-index of bootstrap modals
.modal {
  z-index: 1620;
}
.modal-dialog {
  z-index: 1630;
}
.ctools-modal-dialog .modal-body {
  width: 100% !important;
  overflow: auto;
}
.modal-backdrop {
  z-index: 1610;
}

// Element invisible fix
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}

// Paragraphs.
p:last-child {
  margin-bottom: 0;
}

// Help region.
// .region-help {
//   > .glyphicon {
//     font-size: $font-size-large;
//     float: left;
//     margin: -0.05em 0.5em 0 0;
//   }
//   .block {
//     overflow: hidden;
//   }
// }

// .glyphicon {
//   position: relative;
//   top: 1px;
//   display: inline-block;
//   font-family: 'fontello';
//   font-style: normal;
//   font-weight: normal;
//   line-height: 1;
//   -moz-osx-font-smoothing: grayscale;
// }

// Form elements.
input, textarea, select, .uneditable-input {
  max-width: 100%;
  width: auto;
}
input.error {
  color: $state-danger-text;
  border-color: $state-danger-border;
}
fieldset legend.panel-heading {
  float: left;
  line-height: 1em;
  margin: 0;
}
fieldset .panel-body {
  clear: both;
  display: inherit;
}
fieldset .panel-heading a.panel-title {
  color: inherit;
  display: block;
  margin: -10px -15px;
  padding: 10px 15px;
  &:hover {
    text-decoration: none;
  }
}
.form-group:last-child,
.panel:last-child {
  margin-bottom: 0;
}
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.form-actions{
  clear: both;
}
.managed-files.table {
  td:first-child {
    width: 100%;
  }
}
div.image-widget-data {
  float: none;
  overflow: hidden;
}
table.sticky-header {
  z-index: 1;
}
.resizable-textarea textarea {
  border-radius: $border-radius-base $border-radius-base 0 0;
}
.text-format-wrapper {
  // Use same value as .form-group.
  margin-bottom: 15px;
  > .form-type-textarea,
  .filter-wrapper {
    margin-bottom: 0;
  }
}
.filter-wrapper {
  border-radius: 0 0 $border-radius-base $border-radius-base;
  .panel-body {
    padding: 7px;
  }
  .form-type-select {
    min-width: 30%;
    .filter-list {
      width: 100%;
    }
  }
}
.filter-help {
  margin-top: 5px;
  text-align: center;
  @include min($sm) {
    float: right;
  }
  .glyphicon {
    margin: 0 5px 0 0;
    vertical-align: text-top;
  }
}
.radio, .checkbox {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.help-block, .control-group .help-inline {
  font-size: $font-size-small;
  margin: 5px 0 10px;
  padding: 0;
}
.panel-heading {
  display: block;
}
a.tabledrag-handle .handle {
  height: auto;
  width: auto;
}

// Error containers.
.error {
  color: $state-danger-text;
}
div.error,
table tr.error {
  background-color: $state-danger-bg;
  color: $state-danger-text;
  border-color: $state-danger-border;
  .close {
    line-height: 0.8;
  }
}
.form-group.error,
.form-group.has-error {
  background: none;
  label, .control-label {
    color: $state-danger-text;
    font-weight: 600;
  }
  input, textarea, select, .uneditable-input {
    color: $input-color;
  }
  .help-block, .help-inline {
    color: $text-muted;
  }
}

// Lists
.nav-tabs {
  margin-bottom: 10px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.tabs--secondary {
  margin: 0 0 10px;
}

// Submitted
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

// Autocomplete and throbber
// .form-autocomplete {
//   .glyphicon {
//     color: $gray-light;
//     font-size: 120%;
//     &.glyphicon-spin {
//       color: $brand-primary;
//     }
//   }
//   .input-group-addon {
//     background-color: rgb(255, 255, 255);
//   }
// }


/**
 * Missing Bootstrap 2 tab styling.
 * @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
 * @see http://bootply.com/74926
 */
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-small;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
// .tabs-below {
//   > .nav-tabs {
//     border-top: 1px solid $nav-tabs-border-color;
//     > li {
//       margin-top: -1px;
//       margin-bottom: 0;
//       > a {
//         border-radius: 0 0 $border-radius-base $border-radius-base;
//         &:hover,
//         &:focus {
//           border-top-color: $nav-tabs-border-color;
//           border-bottom-color: transparent;
//         }
//       }
//     }
//     > .active {
//       > a,
//       > a:hover,
//       > a:focus {
//         border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
//       }
//     }
//   }
// }

// // Left and right tabs.
// .tabs-left,
// .tabs-right {
//   > .nav-tabs {
//     padding-bottom: 20px;
//     width: 220px;
//     > li {
//       float: none;
//       &:focus {
//         outline: 0;
//       }
//       > a {
//         margin-right: 0;
//         margin-bottom: 3px;
//         &:focus {
//           outline: 0;
//         }
//       }
//     }
//   }
//   > .tab-content {
//     border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
//     @include box-shadow(0 1px 1px rgba(0,0,0,.05));
//     border: 1px solid $nav-tabs-border-color;
//     overflow: hidden;
//     padding: 10px 15px;
//   }
// }

// // Left tabs.
// .tabs-left {
//   > .nav-tabs {
//     float: left;
//     margin-right: -1px;
//     > li > a {
//       border-radius: $border-radius-base 0 0 $border-radius-base;
//       &:hover,
//       &:focus {
//         border-color: $nav-tabs-link-hover-border-color $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
//       }
//     }
//     > .active > a,
//     > .active > a:hover,
//     > .active > a:focus {
//       border-color: $nav-tabs-border-color transparent $nav-tabs-border-color $nav-tabs-border-color;
//       @include box-shadow(-1px 1px 1px rgba(0,0,0,.05));
//     }
//   }
// }

// // Right tabs.
// .tabs-right {
//   > .nav-tabs {
//     float: right;
//     margin-left: -1px;
//     > li > a {
//       border-radius: 0 $border-radius-base $border-radius-base 0;
//       &:hover,
//       &:focus {
//         border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
//         @include box-shadow(1px 1px 1px rgba(0,0,0,.05));
//       }
//     }
//     > .active > a,
//     > .active > a:hover,
//     > .active > a:focus {
//       border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
//     }
//   }
// }

// Checkbox cell fix.
th.checkbox,
td.checkbox,
th.radio,
td.radio {
  display: table-cell;
}

// Views UI fixes.
// .views-display-settings {
//   .label {
//     font-size: 100%;
//     color:#666666;
//   }
//   .footer {
//     padding:0;
//     margin:4px 0 0 0;
//   }
// }
// .views-exposed-form .views-exposed-widget .btn {
//   margin-top: 1.8em;
// }

// Radio and checkbox in table fixes
table {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    max-width: inherit;
  }
}

// Exposed filters
.form-horizontal .form-group label {
  position: relative;
  min-height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  text-align: right;

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage((2 / $grid-columns));
  }
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: $alert-link-font-weight;
}
.alert-success {
  a, a:hover, a:focus {
    color: darken($alert-success-text, 10%);
  }
}
.alert-info {
  a, a:hover, a:focus {
    color: darken($alert-info-text, 10%);
  }
}
.alert-warning {
  a, a:hover, a:focus {
    color: darken($alert-warning-text, 10%);
  }
}
.alert-danger {
  a, a:hover, a:focus {
    color: darken($alert-danger-text, 10%);
  }
}

// Override image module.
// div.image-style-new,
// div.image-style-new div {
//   display: block;
// }
// div.image-style-new div.input-group {
//   display: table;
// }

// td.module,
// .table-striped>tbody>tr:nth-child(odd)>td.module,
// .table>tbody>tr>td.module {
//   background: $table-border-color;
//   font-weight: 700;
// }