@mixin block-title {
  font-weight: 300;
  font-size: $font-size-h1 * 0.8;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $vgap3;
}

@mixin date($theme: 'copper') {
  $bg: map-get($jpc-backgrounds, $theme);
  $color: map-get($jpc-themes, $theme);

  font-size: 3.5em;
  font-weight: 900;
  color: $color;
  margin-top:-0.1em;    
  .sep {
    @include sr-only;
  }

  .day-month, .year {
    padding: 0px 10px;
    line-height: 1;
    display: block;
    letter-spacing: 0.04em;
  }

  .year {
    letter-spacing: 0.18em;
    line-height: 1;
    display: inline;
    padding-right:0;
  }

  .highlight {
    background-color: $bg;
  }
}


//
// Teaser, theme colour
// --------------------------------------------------
@mixin teaser-variant($theme: 'copper') {
  $bg: map-get($jpc-backgrounds, $theme);
  $color: map-get($jpc-themes, $theme);


  a:not(.btn) {
    color: $color;
    &:hover, &:focus {
      color: $flame;
    }
  }
  .btn {
    background-color: $color;
    border-color: $color;
    &:hover, &:focus {
      transform: none;
      padding-left: $gap3;
      background-color: $flame;
      border-color: $flame;
      color: mix(white, $gold, 80%);
    }
  }
  .field-type-datetime {
    @include date($theme);
  }

}

//
// Teaser Boxes
// --------------------------------------------------

@mixin teaserbox-variant($theme: 'copper') {
  $bg: map-get($jpc-backgrounds, $theme);
  $color: map-get($jpc-themes, $theme);

  font-size: $font-size-small;

  // color: $maroon;
  .teaserbox__header {
    background: $color;
  }
  .teaserbox__highlight:not(.header-after-image) {
    // @include max($sm) {
    //   color: $color;
    //   .title {
    //     color: $color;
    //   }
    // }
  }
  .teaserbox__body {
    background-color: $bg;
    a.more {
      color: $color;
      &:hover, &:focus {
        font-size: 1.1em;
        color: darken($color, 5%);
      }
    }
  }
  .teaserbox__footer {
    background: $color;
  }
  .btn {
    background-color: $color;
    border-color: $color;
    &:hover, &:focus {
      transform: none;
      //padding-left: $gap3;
      //padding-right:$gap;
      background-color: $flame;
      border-color: $flame;
      color: mix(white, $gold, 80%);
    }
  }

  &:hover .teaserbox__footer .title a {
    color: $gold-light; 
  }

}

@mixin teaserbox-listitem($border-bottom-color: $maroon-light) {
  padding-bottom: 15px;
  padding-top: 15px;

  &:not(.views-row-last) {
    border-bottom:1px solid $border-bottom-color;
  }
  &.views-row-first {
    padding-top: 0;
  }
  &.views-row-last {
    padding-bottom: 0;
  }
}
