
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Layouts
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


body.cke_editable {
  padding: 20px !important;
}

.layout {
  position: relative;
}

/*
   Header
   ========================================================================== */
header.layout {
  width: 100%;
}


/*
   Hero
   ========================================================================== */
.layout-hero { }

/*
   Main
   ========================================================================== */
.tabs {
  @extend .container-smooth;
  margin: $vgap2 auto $vgap4 auto;
  ul {
    display: inline-block;
  }
}

.not-front #main {
  padding-top: $vgap3;
  @include min($sm) {
    padding-top: $vgap6;
  }
}

.main-content {
  // #block-system-main {
  //   padding-bottom: $vgap4;
  //   @include min($sm) {
  //     padding-bottom: $vgap6;
  //   }
  // }
}
.layout-above-content {
  padding-top: $vgap3;
  padding-bottom: $vgap3;
  @include min($sm) {
    padding-top: $vgap6;
    padding-bottom: $vgap6;
  }
}
.layout-below-content {
  padding-top: $vgap * 3.5;
  padding-bottom: $vgap4;
  @include min($sm) {
    padding-top:$vgap6;
    padding-bottom: $vgap6;
  }
}



/*
  Canyon
   ========================================================================== */
.layout-canyon {
  padding: $vgap3 0;  
  @include min($sm) {
    padding: $vgap6 0;  
  }
}



/*
   Above Footer
   ========================================================================== */
.layout-above-footer {
  padding: $vgap3 0;  
  @include min($sm) {
    padding: $vgap6 0;  
  }
}


/*
   Front
   ========================================================================== */
.layout-jpc-group {
  padding: $vgap4 0;
  @include min($sm) {
    padding: $vgap5 0;
  }
}

// Tribal Link
.layout-triballink {  
  padding: $vgap3 0;
  @include max($sm) {
    div[class*='col-']:nth-child(2) {
      text-align: center;
      margin-top: $vgap2;
    }
  }
}

.front .layout {
  text-align: center;
  .block {
    text-align: center;
  }
}


/* Drupal 7 / DS Layouts   
   ========================================================================== */
.ds-layout {
  position: relative;
  
}

/*
   Footer
   ========================================================================== */
#footer {
  
}
.layout-footer {
  padding: $vgap3 0;
}
.layout-subfooter {
  padding: $vgap2 0 $vgap2 0;
}

//
// Global elements e.g. header, footer
// --------------------------------------------------

/*
   Header
   ========================================================================== */
header#header {
  padding-top: $vgap;
  padding-bottom: $vgap;

  @include collapsed {
    box-shadow: 0 1px 7px rgba(0,0,0,.1);
  }

  @include min($kindle) {  
    text-align: center;
    padding-top: $vgap3;
    padding-bottom: $vgap3;
  }
  .logo { 
    max-width: 220px;
    display: block;
    padding: 10px 5px;

    @include min($xs) {
      max-width: 250px;
    } 
    @include min($kindle) {
      padding: 0;
      margin-left:auto;
      margin-right:auto;
    }
    @include min($sm) {
      max-width: 300px;
    }     
    // @include min($lg) {
    //   max-width: 350px;
    // }   
    // @include min($lg) {
    //   max-width: 400px;
    // }
  }
}

// see menu.scss for navmenu styles



/*
   Main
   ========================================================================== */
#main {

  // a:not(.btn) {
  //   //text-decoration: underline;
  // }
  // a:not(.btn):visited {
  //   //color: darken($text-color, 10%);
  // }

  // .node .layout-hero {
  //   margin-bottom: $vgap3;
  // }

  @include max($sm) {
    .layout > hr {
      margin:0 $gap $vgap5 $gap;
    }
  }

}


/*
   Sidebar
   ========================================================================== */

$sidebar-left-padding: 30px; //$line-height-computed;

aside.sidebar {

  .h3 {
    color: $maroon;
    font-weight: 900;
  }

  .block {
    margin-bottom: $vgap4;
  }
  .h2, .block-title {
    padding-bottom: $vgap;
    border-bottom:1px solid $flame;
  }

  .subheading:not(:first-child) {
    margin-top: $vgap2;
  }
  hr {
    border-color: $flame;
  }

  li .icon {
    font-size: 1.8em;
    vertical-align: middle;
    margin-right: $gap;
  }

  a {
    text-decoration: none;
    //color: $black;
    &:hover, &:focus {
      text-decoration: none;
      //color: $green;
    }
  }
  @include max($sm) {
    clear: both;
    float: none;
    margin-top: $vgap3;
  }

  @include min($sm) {
    color: $black;

    .inner {
      padding: $vgap5 0;// ($sidebar-left-padding) $vgap5 ($sidebar-left-padding);
    }
   
    //font-size: $font-size-small;
    margin-top: em(8px); // account for lining up to text-top

    // Theme light
    // &:not(.sidebar-light) {
    //   color: #fff;
    //   .inner {
    //     background-color: $maroon;
    //   }
    //   a {
    //     @include link-colors(#fff, $hover: $gold);
    //   }
    //   .h2, .h3, .h4 {
    //     color: #fff;
    //   }
    //   .h3 {
    //     color: $maroon-light;
    //   }
    //   .h2, .block-title {
    //     color: #fff;
    //   }
    // }

  }

 
}

/*
  JPC GROUP
  ========================================================================== */
.list-jpc-group {
  display: inline-block;
  margin-bottom: 0;    
  vertical-align: middle;
  > li {
    padding: 0 5px;
    > a {
      display: block;
      padding: 10px 15px;
    }
    img {
      height: em(25px);
      &.iws {
        height: em(31px);
      }
      &.fb {
        height: em(28px);
      }
    }
  }
}


/*
   Main Menu
   ========================================================================== */
@include min($grid-float-breakpoint) {
  #navbar.navbar-default { 
    border:none;
    height: $navbar-height;
    //box-shadow: 0 1px 5px rgba(0,0,0,.25);
    @include min($lg) {  
      background: linear-gradient(to right, $copper 0%, $maroon 20%, $maroon 80%, $copper 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
       // background: linear-gradient(to right, $gold 0%, $orange 5%, $copper 10%, $maroon 15%, $maroon 75%, $copper 85%, $orange 95%, $gold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
}


#main-menu {
  border:none;
  .menu-primary {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-nav>li {
    padding-left:0;
    padding-right:0;
    @include max($grid-float-breakpoint) {
      &:not(:last-child) { border-bottom: 1px solid mix($maroon, $white, 94%); }

    }
    > a {
      text-transform: uppercase;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 600;
    //  font-size: 16px;// $font-size-small;
      @include min($md) {
        padding-left: $gap;
        padding-right: $gap;
      }
      //font-weight: 700;
      &:active {
        background-color: $gold;
      }
    }
  }
  .dropdown-menu {
      background-color: $navbar-default-bg;
      border-color: transparent;;
      color: $navbar-default-color;  
      background-clip: border-box;
      box-shadow: none;
      border: none;
      padding:0;
      min-width: 199px;
        > li:not(:last-child) {
          border-bottom: 1px solid mix($maroon, $white, 94%);
        }
        > li > a {
          color: $navbar-default-color;
          padding:10px 20px;
          //text-align: center;
          &:hover, &:focus {
            background-color: $gold;
          }
        }

      @include max($grid-float-breakpoint) {
        display: block;
        position: relative;
        float: none;
        > li {
          border-bottom: 1px solid mix($maroon, $white, 94%);
        }
        > li > a {
          text-align: left;
          padding: 10px $gap 10px $gap2;
          // &:before {
          //   content: "- ";
          //   position: absolute;
          //   color: $flame;
          //   margin-right: 10px;
          // }
        }
      }
  }

  @include minmax($grid-float-breakpoint, $md) {
    .navbar-nav>li>a{ 
      //font-size:0.9em;
      padding-left: $gap;
      padding-right: $gap;
    }
  }

  @include min($grid-float-breakpoint) {
    text-align: center;
    max-width: $container-md;
    margin: 0 auto;
    width: auto;
    padding: 0;
    .menu-primary {
      //display: inline-block;
      float: none;
      margin-bottom: 0;
      width:100%;
      display: table;
      // margin-left: -$gap;
      // margin-right: -$gap;
    } 
    .dropdown:hover .dropdown-menu {
      display: block;
      margin-top: 0; // remove the gap so it doesn't close
    }
    .navbar-nav>li {
      display: table-cell;
      float: none;
      // &:first-child {
      //   text-align: left;
      // }
      // &:last-child {
      //   text-align: right;
      // }
     //width: percentage(1/7);
    }
  }
}


.navbar-toggle {
  .icon-bar {
    background-color: $copper;
    &:nth-child(2) {
      background-color: $yellow;
    }
    &:nth-child(3) {
      background-color:$flame;
    }
  }

  margin-top: 18px;
  // @include min($kindle) {
  //   margin-top:36px;
  // }
}



/*
   Admin stuff
   ========================================================================== */
.node-form {
  .chosen-container.form-select {
   width: 500px;
  }
}

/*
   Tabs
   ========================================================================== */
.tabs ul{
  a {
    color: $admin-tabs-color;
    font-weight: 900;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: none;
    &.active {
      background-color: $admin-tabs-active-bg;
      color: $admin-tabs-active-color
    }
  }
}


/*
   User login
   ========================================================================== */
#user-login {
  max-width: 600px;
  margin-bottom: 50px;
}
