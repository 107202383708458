// General View styling
#block-views-programs-block-2 .block-title, 
section.layout:not(.layout-middle) .block-title {
  @include block-title;
}

// View-content - extend make-row
#block-views-team-block-team-teaser,
#block-views-articles-block,
#block-views-services-block {
  .view-content {
    @include make-row;
  }
}


// Teaser Boxes
// --------------------------------------------------


@each $key, $val in $jpc-themes {
  .teaserbox--#{$key} {
    @include teaserbox-variant($key);
  }
}


.teaserbox {
  margin-bottom: $vgap2;
  color: $gray-darker;

  @include element('image') {
    //max-height: 284px;
    overflow: hidden;
    position: relative;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }


  @include element('body') {
    padding: 20px $gap;
    font-size: $font-size-small;
    line-height: 1.3333;
    text-align: left;
    position: relative;
    a:not(.more) { 
      display: inline-block;
      color: $gray-darker;
      //color: $maroon;
      //link-color($link, $hover: null, $active: null, $visit: null, $important: null) {
      //@include link-color($maroon, $hover: darken($maroon, 8%), $active: $flame);
    }
    .field-name-body a .icon {
      font-size:0.8em;
    }
  }
  @include element('highlight') {
    color: #fff;
    padding: 5px $gap;
    .title { 
      padding: 4px 0 4px 0;// $gap;
      margin-bottom: 0;
      //text-transform: uppercase;
      color: #fff;
      font-size: $font-size-base;
    }
    a {
      @include link-color(#fff, $active: $gold);
    }
  }


  @include element('footer') {
    padding: 7px $gap;
    top: auto;
    bottom: -10px;
    min-height: 0;
    position: absolute;
    transition: padding 0.3s, height 0s, bottom 0s, translate 0s;
    width:100%;
    
  }

  &:hover .teaserbox__footer {
    padding-bottom:30px;
    transition: padding 0.3s, height 0s, bottom 0s, translate 0s;
  }

  @include element('image--tint') {
    .field-type-image {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($gray, 0.05); 
        transition: all .3s linear;
      }
      &:hover:before, &:focus:before {
        display: none;
      }
    }
    
  }

  // &:hover .title a {
  //   color: $gold-light; 
  // }

  img {
    //@include min($sm) {
      max-width: none;
      width: 100%;
    //}
  }

  .btn {
    margin-top: 1px;
    @include min($sm) {
      margin-top: $gap;
    }
  }
  .btn-cta {
    width:100%;
    i {
      font-size:1.4em;
      margin-left: 8px;
      vertical-align: middle;
    }
  }

  @include mod('cta') {
    &:nth-of-type(3n+1) {
      @include teaserbox-variant('maroon');
    }
    &:nth-of-type(3n+3) {
      @include teaserbox-variant('gold');
    }
  }

}

.related-programs .teaserbox,
.teaserbox.col {
  @include min($md) {
    img {
      width: 100%;
    }

    &:nth-of-type(1) .inner {
      margin-left: 0; margin-right: $gap2;
    }
    &:nth-of-type(2) .inner {
      margin-left: $gap;
      margin-right: $gap;
    }
    &:nth-of-type(3) .inner {
      margin-left: $gap2; margin-right: 0;
    }
  }
}


// Teaser list
#block-views-programs-block-3 {
  .views-row a {
    display: block;
  }
  .views-row {
    @include teaserbox-listitem(rgba($yellow, 0.6));
  }
}

// Current progs // All progs
#block-views-programs-block,
#block-views-programs-block-1 {
  margin-bottom: 100px;
  .views-row-last hr {
    display: none;
  }
}


// Team block - CEO
#block-nodeblock-10 {
  @include max($sm) {
    .h2 {
      font-size: 1.8em;
      text-align: center;
    }
    .node {
      padding-bottom: $vgap3;
      border-bottom: 1px solid #ddd;
    }
  }
}
#block-views-team-block-ceo-teaser {
  @include max($sm) {
    display: none;
  }
}


// Team block - All
#block-views-team-block-team-teaser {
  h2.company {
    margin-bottom: $vgap2;
    color: $black;
  }
  .view-header {
    margin: -10px auto $vgap5;
    max-width: $container-sm;
    ul {
      text-transform: uppercase;
      font-weight:bold;
      font-size: 1.1em;
      text-align: center;
      li {
        margin-bottom:10px;
      }
      a {
        padding: 10px 20px;
        background-color: $maroon;
        color: #fff;
        display: block;
        line-height:1.1;
        width:100%;
        min-width: 110px;

        &:hover {
          background-color: darken($maroon, 5%);
        }

        @each $key, $primary in $jpc-group {
          &.#{$key} {
            background-color: $primary;
            &:hover {
              background-color: darken($primary, 5%);
            }
          }
        }
      }
      @include max($xs) {
        li {
          display: block;
          margin-bottom: 5px;
        }  
      }
    }
  }
  .rows-wrapper {
    position: relative;
    @include clearfix;
  }
  .company-wrapper {
    position: relative;
    clear:both;
    padding-bottom: $vgap2;
    hr {
      clear:both;
      margin-left: $gap;
      margin-right: $gap;
    }
  }

  @include min($sm) {
    // .company-wrapper hr {
    //   display: none;
    // }
  }

}

// Articles
#block-views-articles-block-1 {
  .views-row {
    @include teaserbox-listitem(mix($maroon, #fff, 50%));
  }
}

// on News page
.layout-featured-news .view {
  @include min($md) {
    margin-top: -40px;
  }
  .views-row {
    @include make-md-column(4); 
    @include make-sm-column(4);
    margin-bottom: $vgap3;
    @include max($sm) {
      &.views-row-last { margin-bottom: $vgap; }
    }
  }

  .view-content {
    @include clearfix;
  }

  .pager {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;  
    margin-top: $vgap2;
  }


  @include min($md) {
    .views-row-1 { padding-right: $gap2; }
    .views-row-2 { padding-left: $gap; padding-right: $gap; }
    .views-row-3 { padding-left: $gap2; }
  }
}

#block-views-articles-block {
  .views-row {
    @include make-md-column(6);
  }
  .views-row-odd {
    clear:both;
  }
}

// Milestones / Accordions on About Page
#block-views-milestones-block {  
  position: relative;
  padding-bottom: $vgap2;
  margin-bottom: $vgap7; 
  h1.block-title {
    margin-bottom: 80px;
  }
  // Left Align Block
  .views-row-odd article {
    margin-right: 55%;
    text-align: right;
    h2.date {
      position: relative;
    }
    &:before {
      right: -120px;
    }
  }
  // Right Align Block
  .views-row-even article {
    margin-left: 55%;
    h2.date {
      left: -105px;
    }
    &:before {
      left: -120px;
      transform: rotate(180deg);
    }
  }
  article {
    &:before {
      z-index: -1;
      background-image: url('../images/logo-part-left.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      content: " ";
      height: 250px;
      width: 150px;
      position: absolute;
      display: block;
      background-size: 100%;
      top: -105px;
    }

  }
  h2.date {
    background-color: $white;
    color: $maroon;
    //position: absolute;
    font-size: 3em;
    //top: -70px;
    font-weight: 300;
    font-family: $opensans;
    .day-month, .sep {
      display: none;
    }
  }
  // Middle Line
  &:before {
    content: " ";
    //display: none;
    display: block;
    position: absolute;
    height: calc(100% - 130px);   
    background: linear-gradient(to bottom, transparent 0%, $gold 2%, $orange 30%, $flame 65%, $copper 88%, $copper 95%, transparent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    //background-color: $flame;// $gray;// $yellow;// tint($yellow,70%);
    width: 1px;
  //  border-left:1px dashed $yellow;// red;
    margin: 0 auto;
    float: none;
    margin-left: calc(50% - 1px);//49%;
    top:110px;

    @include max($sm) {
      margin-left: 0; 
      width: 1px; 
      display: block;
    }
  }
  @include max($sm) {
      .views-row-even, .views-row-odd {
        article {
          text-align: left;
          margin: 0;
          padding-left: 50px;
          padding-bottom: $vgap3;
          margin-bottom: $vgap4;//130px;
          &:before {
             right: inherit; 
             left: 25px;
             top: -120px;
             transform: rotate(180deg);
          }
          h2.date {
            right: inherit;
            //left: 15px;
            //top: -100px;
          }
        }
      }
  }

  .views-row-last .node {
    border-bottom: none !important;
  }

  
}

// Our difference
#block-nodeblock-47 {
  margin-bottom: $vgap4;
}


// Awards
#block-views-awards-block {
  padding-left:$gap;
  padding-right: $gap;

  img {
    height: 80px;
    @include min($sm) {
      height: 100px;
    }
    width: auto;
  }
  .slick__slider {
    margin-top: $vgap2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    @include min($lg) {
      max-width: $xxlg;    
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .slick-track{
    display: flex;
    text-align: center;
  }
  .slick__slide{
    display: flex;
    height: auto;
    padding: 0 $gap;
    align-items: center; //optional
    justify-content: center; //optional
  }

}

// Services
// 
#block-views-services-block {
  //max-width: $container-sm - 100px;
  margin: 0 auto;
  .view-content {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
  .views-row {
    &.views-row-last hr {
      display: none;
    }

    &:nth-child(3n+1) .node-service .node-title {
      color: $gold-dark;
    }
    &:nth-child(3n+3) .node-service .node-title {
      color: $maroon;
    }

    flex: 0 0 100%;
    max-width: 100%;

    .node {
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
      @include min($sm) {
        max-width: 80%;  
      }
      @include min($lg) { max-width: 75%;  }
    }

    @include min($sm) {
      flex: 0 0 50%;
      max-width: 50%;
      hr {
        display: none;
      }
    }


  }

}


#block-views-locations-block {

  margin-top: $vgap6;

  @include max($sm) {
    margin-top: $vgap4;
    border-top:1px solid #ddd;
    padding-top: $vgap3;
  }

  .block-title {
   // margin-bottom: $vgap2;
  }

  .views-row {
    &.views-row-last hr {
      display: none;
    }

    @include min($md) {
      h2 {
        display: inline-block;
        width: 42.5%;
        padding-right: $vgap2;
      }
      .field {
        display: inline-block;
      }
    }

  }

}

//
// Google Map
// --------------------------------------------------

#google-map {
  width:100%;
  height: 550px;
  //border-top: 40px solid $orange;
}

#block-jpc-gmap-jpc-gmap {
  margin-top: $vgap6;
  .block-title {
    font-size: $font-size-h1 !important;
    margin-bottom: $vgap2 !important;
  }
  .bodyContent {
    font-weight: 400;
    h3 {
      margin-top: 6px;
      font-size: 15px;
    }
    .pre-line {
      white-space: pre-line;
    }
    .phone {
      margin-bottom: 5px;
    }
    a:hover {
      text-decoration: underline !important;
    }
    .get-directions {
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 5px;
      a { 
        color: $flame; 
        position: relative;
        &:after {
          top:-2px;
          position: absolute;
          content: "\2794";
          color: $copper;
          margin-left: 5px;
          font-size:15px;
        }
      }
      
    }
  }
}


// Contact form
// --------------------------------------------------
#block-webform-client-block-27 {
  #enquire {
    margin-top:$vgap3;
    margin-bottom: $vgap3;
  }

  .btn-enquire {
    padding: 10px 30px;
    width: 100%;
    @include min($xs) {
      width: 365px;
    }
  }

  .btn-enquire:not(.collapsed) i {
    transform: rotate(180deg);
  }

  .form-actions {
    text-align: right;
  }
  
  .block-title {
    margin-bottom: $vgap2;
  }
}

// Resume form
// --------------------------------------------------
#block-webform-client-block-28 {
  .webform-component-file {
    margin-bottom: $vgap2;
  }
}

// General
// --------------------------------------------------
.block-webform form {
  @include min($kindle) {
    .webform-component--first-name {
      max-width: 49%; float: left;
    }    
    .webform-component--last-name {
      max-width: 49%; float: right;
    }
  }
}


