//
// Slick sliders
// --------------------------------------------------
@charset "UTF-8";
// Default Variables
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
$slick-font-path: "../../../../all/libraries/slick/slick/fonts/"; // !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../../../../all/libraries/slick/slick/"; // !default;
$slick-arrow-color: $gray; //white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\f104';//$fa-var-angle-left;
$slick-next-character: '\f105'; //$fa-var-angle-right;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 8px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-arrow-size: 2em;
$slick-font-family: "Font Awesome 5 Pro";


@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

.slick {
    position: relative;
}


/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}


/* Icons */

@if $slick-font-family=="slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: $slick-arrow-size;
    width: $slick-arrow-size;
    //line-height: 0px;
    //font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    top: 50%;
    transform: translate(0, -50%);
    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        @include max($sm) {
         // background-color: $gray-lighter;
        }
        font-family: $slick-font-family;
        font-size: $slick-arrow-size;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        font-weight: normal;
        speak: none;
        text-decoration: inherit;
        width: 1em;
        //margin-right: 0.05em;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;

    }
}

.slick-prev {
    &:before {
        content: $slick-prev-character;
    }
    //left: 40px;
    //@include max($kgm) {
      left: -5px;
    //} 
    @include max($sm) {
      left: -10px;
    }
}

.slick-next {
    &:before {
        content: $slick-next-character;
    }
    //right: 40px;
    //@include max($kg) {
      right:  -5px;
    //}
    @include max($sm) {
      right: -10px;
    }
}


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0; // 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
