$font-size-larger:        ceil(($font-size-base * 1.4)) !default; // Added by Skye
$font-size-xs:            11px !default;
$font-size-xsmall:        $font-size-xs !default;
$font-size-xxs:           9px !default;
$container-smooth-max:    $container-lg;//$container-md;
$img-path: "../images";
$image-path: "../images";
$text-shadow: 0px 1px 2px rgba(0,0,0,.2) !default; // Added by Skye
$text-shadow-hover: 1px 1px 1px rgba(0,0,0,.3) !default; // Added by Skye


@mixin collapsed {
  @media screen and (max-width: #{$grid-float-breakpoint-max}) {
    @content;
  }
}

@mixin uncollapsed {
  @media screen and (min-width: #{$grid-float-breakpoint}) {
    @content;
  }
}