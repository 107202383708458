@import "../base/variables";
@import "../base/mixins";

.padding-offset-left {
  padding-left: percentage(1 / $grid-columns);
}

.no-gutter [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

// Centered columns styles
// from http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-centered-columns
.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

@mixin container-smooth($max: $container-smooth-max) {
  @include container-fixed;
  // max-width: $max;
  // position: relative;
  // padding-left: ($grid-gutter-width/2);
  // padding-right: ($grid-gutter-width/2);

  // @include minmax($sm, $md) {
  //   padding-left: $gap3;
  //   padding-right: $gap3;
  // }
}


.container-smooth {
  @include container-smooth;
}

div[class*='container-smooth'] div[class*='container-smooth'] {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: $screen-xs-min) {
  @include make-grid(xxs);
}

// Generate the extra extra small columns
@mixin make-xxs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin make-xxs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}

@mixin make-xxs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}

@mixin make-xxs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}


// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-xs-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

@mixin make-xs-column-offset($columns) {
  @media (min-width: $screen-xs-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-xs-column-push($columns) {
  @media (min-width: $screen-xs-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-xs-column-pull($columns) {
  @media (min-width: $screen-xs-min) {
    right: percentage(($columns / $grid-columns));
  }
}
