@media print {
  * {
    background-color: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body {
    padding-top: 0;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  a,
  .link {
    color: #000;
    text-decoration: underline;
  }
  .button, .button--primary {
    background: none !important;
  }
  .messages {
    border-width: 1px;
    border-color: #999;
  }
  .is-collapse-enabled .tabs {
    max-height: 999em;
  }
  .is-horizontal .tabs__tab {
    margin: 0 4px !important;
    border-radius: 4px 4px 0 0 !important;
  }
  .dropbutton-multiple .dropbutton .secondary-action {
    display: block;
  }
  .js .dropbutton-widget,
  .js td .dropbutton-widget /* Splitbuttons */ {
    position: relative;
  }
  .js .dropbutton .dropbutton-toggle {
    display: none;
  }
  .js .dropbutton-multiple .dropbutton-widget {
    background: none;
    border-radius: 4px;
  }
  input.form-autocomplete, input.form-text, input.form-tel, input.form-email, input.form-url, input.form-search, input.form-number, input.form-color, input.form-file, textarea.form-textarea, select.form-select {
    border-width: 1px;
  }
}
