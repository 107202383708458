
//
// Typography
// --------------------------------------------------


// Links
.teaserbox__body, .node.full {
  a:hover {
    text-decoration: underline;
  }
}


// Headings
// -------------------------
// h1, .h1, h2, .h2, h3, .h3, h3, .h3 {
//   font-family: $opensans;
// }
h1, .h1 {
  font-size: em($font-size-h1);
  text-transform: uppercase;
  line-height: 1.1;
  //letter-spacing: 0.04em;
  font-weight: 300;//700;
  color: $copper;
  margin-top: 0;
  @include uncollapsed {
    padding-bottom: $vgap2;
  }
}

.node-header {
  //margin-top: 0;
  //padding-bottom: $vgap2;
}

.page-header {
  padding: 0 $gap2;
  margin: 0 auto $vgap3; 
  letter-spacing: 0.04em;
  //max-width: $container-sm;
  //@include uncollapsed {
  text-align: center;
  border-bottom: 1px solid $orange;//$flame;
  padding-bottom: $vgap2;

  @include min(650px) {
   // max-width: 630px;
  }

  @include min($md) {
    max-width: $container-md;//sm;
  }

  //}
}

h2, .h2 {
  font-size: em($font-size-h2);
 // text-transform: uppercase;  
 // letter-spacing: 0.01em;
  color: $copper;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.3;
  @include max($sm) {
    letter-spacing: 0;
  }
}

h3, .h3 {
  font-size: em($font-size-h3);
  //letter-spacing: -0.02em;
  margin-bottom: $vgap * 0.5;
  margin-top: 0;
}

h4, .h4 { margin-top: 0; font-size: em($font-size-h4); }
h5, .h5 { margin-top: 0; font-size: em($font-size-h5);  text-transform: none;}
h6, .h6 { margin-top: 0; font-size: em($font-size-h6);  text-transform: none;}



.h4, .h5, .h6, h4, h5, h6 {
  margin-bottom: $gap;
}

a.contextual-links-trigger {
  background: transparent url(/modules/contextual/images/gear-select.png) no-repeat 2px 0 !important;
  border: 1px solid transparent  !important;;
  height: 18px  !important;;
  margin: 0  !important;;
  padding: 0 2px  !important;;
  outline: none  !important;;
  text-indent: 34px  !important;;
  width: 28px  !important;;
  overflow: hidden;
  -khtml-border-radius: 4px  !important;;
  -moz-border-radius: 4px  !important;;
  -webkit-border-radius: 4px  !important;;
  border-radius: 4px  !important;
}

// Blockquotes
blockquote {
  margin:0 0 $vgap4;
  padding:0;
  border:none;
}

// --------------------------------
// Responsive Typography


@include max($sm) {
  // Decrease by = 2px;
  $dec: 1px;

  body { 
    font-size: $font-size-base - $dec;
  }
  .h1, h1 {
    font-size: 2.1em;//em($font-size-h1 * 0.8);
  }
  .not-front .page-header {
    margin-top: $vgap2;
  }
  .h2 {
    //font-size: em($font-size-h2 * 0.8);
  }
}

// @include min($xxlg) {
//   body { font-size: $font-size-base + 1; }
// }

// @include min($xxxlg) {
//   body { font-size: $font-size-base + 2; }
// }



//
// Accordions / Panels
// --------------------------------------------------


//
// Accordions theme colour
// --------------------------------------------------
@mixin accordion-variant($theme: 'copper') {
  $bg: map-get($jpc-backgrounds, $theme);
  $color: map-get($jpc-themes, $theme);

  .panel-body {
    background-color: $bg;
    @if($theme == 'gold') {
      color: $blue;
    }
  }
  .panel-heading {
    background-color: $color;
  }
  .panel-title {
    background-color: $color;
  }

}

// Base class
.panel {
  margin-bottom: $gap;
  @include accordion-variant();
  
  // Within heading, strip any `h*` tag of its default margins for spacing.
  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    color: #fff;
    font-weight: 700;
    //letter-spacing: 0.04em;
    position: relative;
    a {
      color: #fff;
      display: block;
      width:100%;
      position: relative;    
      padding: $vgap ($gap * 2.5);
      .icon {
        position: absolute;
        right: $gap;
      }
      &:not(.collapsed) {
        .icon{
          @extend .icon-up-open !optional;
        }
      }
    }
  }

  &.collapse.in {

  }

  // Optional heading
  .panel-heading {
    padding: 0;
  }

  // Panel contents
  .panel-body {
    padding: $panel-body-padding;
    color: $gray-dark;
    @include clearfix;
    @include min($sm) {
      text-align: center;
      padding-left: $gap3;
      padding-right: $gap;
    }
  }

}


//
// Buttons
// --------------------------------------------------

@each $key, $val in $jpc-themes {
  .btn--#{$key} {
    background: $val;
    color: #fff;
    border-color: $val;
  }
}

.btn--gold {
  color: $maroon;
}

.btn--orange {
  background-color: $orange;
  color: #fff;
  border-color: $orange;
}

.btn {
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  transition: background-color, 0.25s, color, 0.25s, transform, 0.4s;
  padding-left: $gap2;
  padding-right: $gap2;
  margin-top: $vgap;
  font-size: $font-size-base - 1px;
  transition: all 0.25s ease-in-out;

  i {
    margin-left: 6px;
    margin-right:-3px;
    color: $gold;
    vertical-align: middle;
    transition: all  0.25s ease-in-out;
  }
  
  &:active, &:focus {
    border-color: transparent;
    outline: none;
    box-shadow: 0 0 0 0.1rem $maroon;
  }
  &:hover, &:focus {
    background-color: $orange;//$flame;
    border-color: $orange;// $flame;
    color: mix(white, $gold, 80%);
    color: $white;//$maroon;
    i {
      color: $white;//$gold;
    }
  }
}


.btn.form-submit {
  &:hover, &:focus {
    transform: translateX(0);
  }
}


.btn-back {
  i {
    margin-right: 8px;
    margin-left: 0;
  }
}

.btn-default {
  //@extend .btn--copper;
  @extend .btn--orange;
}

.btn-primary {
  @extend .btn--copper;
  //@extend .btn--orange;
}

.btn--plain {
  background-color: $gray-light;
  color: $white;
  border-color: $gray-light;
  &:hover, &:focus {
    color: $white;
    background-color: $gray;
    border-color: $gray;
  }
}

.btn-wide {
  padding-left: $gap4;
  padding-right: $gap4;
}

button.nav-close {
  opacity: 1;
  font-size: 1.8em;
  display: none;
  float: right;
  font-weight: normal;
  margin-right: $gap;
  margin-top: $gap;
  color: $white;
  opacity: 1;
  &:hover, &:focus {
    color: $white;
  }
}


// Forms
.form-type-managed-file {
  position: relative;

  .form-submit[value="Remove"] {
    margin-left: $gap;
  }
  .input-group-btn .form-submit[value="Remove"] {
    margin-left: 0;
  }
  .form-managed-file.input-group > .form-control {
    padding-top: 3px;
  }
  .form-control span.file {
    font-size: 14px;
  }

}

//
// Navbar
// --------------------------------------------------

.navbar-nav {
 .dropdown-toggle .caret { display: none; }
}


// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

// Setup for positioning nav toggle absolute to top right
.navbar-toggle { 
  border:none;  
  // Bars
  .icon-bar {
    display: block;
    width: 30px;
    height: 3px;
    transition: 300ms ease-in-out;
    background-color: #fff;
    position: relative;
    width: 30px;
    display: block;
    transform: rotate(0deg);        
    top: 0;
    opacity: 1;
  }
  .icon-bar + .icon-bar {
    margin-top: 7px;
  }

  .icon-close {
    display: none;
    background: none;
    font-size: 1.5em;
  }

  &:not(.collapsed) {
    .icon-bar:last-child {
      transform: rotate(-45deg);
      top: -10px;
    }
    .icon-bar:nth-child(2) {
      transform: rotate(45deg);
      top: 9px;
    }
    .icon-bar:nth-child(3) {
      opacity: 0;      
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }

}

// Setup for positioning nav toggle absolute to top right
// Comment out to just leave it Bootstrap default style
.navbar-toggle { 
  z-index: $zindex-navbar + 1;
  position: absolute;
  right: 10px;
  top:0;
  padding:12px 10px;
  float: right;
  margin-right: 0;
}
.pager {
  @include clearfix;
  color: $text-color;
  border-top: 1px solid darken($gray-lightest, 7%);
  padding-top: $vgap2;
  margin-top: $vgap3;
  li { margin: 0 8px; }
  li.pager-current:before {
    content: "page ";
  }
  li a {
    padding: 5px 0 !important;
    color: $black;
    background: none !important;
    border:none !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
  }
}

