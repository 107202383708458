//
// Mixins
// --------------------------------------------------

// =====================================================================
// Media Query Mixin


@mixin min($min: 0, $media: "screen") {
  $query: "only " + $media + " and (min-width: " + $min + ")";
  @media #{$query} {
      @content;
  };
}


@mixin max($max: 3000, $media: "screen") {
  $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  @media #{$query} {
      @content;
  };
}

@mixin minmax($min: null, $max: null, $media: "screen") {
  $query: "only " + $media;

  @if $min != null and $max != null {
      $query: "only " + $media + " and (min-width: " + $min + ") and (max-width: " + ($max - 1) + ")";
  } @else if $min == null and $max != null {
      $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  } @else if $min != null and $max == null {
      $query: "only " + $media + " and (min-width: " + $min + ")";
  }

  @media #{$query} {
      @content;
  };
}



// Vertical align text (flip 90 degrees)
@mixin vertical-column {
  margin-bottom: 0;
  transform: rotate(90deg) translate(100%,100%);
  transform-origin: 100% 100%;
}

// =====================================================================

// Hyphenated (dashed) list
@mixin list-dashed() {
  list-style: none;
  padding-left: 0;

  & > li {
    margin-left:20px;      
    margin-bottom: 0.5em;
    line-height: $line-height-large;
  }

  // Prevent nested li's from getting messed up
  & > li:before {
    content: "\2013";
    margin-left: -20px;
    position: absolute;
  }

}

// =====================================================================

// Convert px to em... helpful for responsive text sizing
@function em($pixels, $context: $font-size-base) {
  @return #{$pixels/$context}em;
}

// Mixin for creating links with colors for different states
@mixin link-colors($link, $hover: null, $active: null, $visit: null, $important: null) {

  @if ($hover == null) {
    $hover: $link;
  }
  @if ($active == null) {
    $active: $link;
  }
  @if ($visit == null) {
    $visit: $link;
  }

  color: $link;
  &:visited {
    color: $visit;
  }
  &:hover {
    color: $hover;   
  }
  &:visited:hover {
    color: $hover;   
  }
  &:active {
    color: $active;
  }

  @if($important != null) {
    color: $link !important;
    &:visited {
      color: $visit !important;
    }
    &:hover {
      color: $hover !important;  
    }
    &:visited:hover {
      color: $hover !important;;  
    }
    &:active {
      color: $active !important;
    }
  }

}

@mixin link-color($link, $hover: null, $active: null, $visit: null, $important: null) {
  @include link-colors($link, $hover, $active, $visit, $important);
}

// Misc mixins

// =====================================================================


@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Horizontal rule mixin
@mixin hr($color: $hr-border, $margin: $line-height-computed) {
  border-top-color: $color;
  margin-top: $margin;
  margin-bottom: $margin;
}

// =====================================================================

// Form element placeholder mixin
@mixin placeholder($color: $input-color-placeholder) {
  ::-webkit-input-placeholder {color: $color; }
  :-moz-placeholder { color: $color;}
  ::-moz-placeholder { color: $color;}
  :-ms-input-placeholder { color: $color;}
}


// =====================================================================
// BEM Mixins

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin mod($modifier) {
    &--#{$modifier} {
        @content;
    }
}


// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// =====================================================================

//
// SASS Placeholders
// --------------------------------------------------

// When you don't need an argument in your mixin, turn it into a placeholder and extend!
// Align mixins
%center-center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
%bottom-center{
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0);
}
%top-center{
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%,0);
}
%left-center{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
}
%right-center{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0,-50%);
}

%vertical-column {
  @include vertical-column;
}

%list-unstyled {
  padding-left: 0;
  list-style: none;
}
