/*
   Footer
   ========================================================================== */

$footer-bg-color:      $orange;//flame;//$copper;//flame;//$maroon;
$footer-text-color:    white;//$copper-light;//$maroon-light;
$subfooter-text-color: lighten($footer-bg-color, 40%);


#footer {
  @include clearfix;
  padding: 0;
  background-color: $footer-bg-color;
  text-align: center;
  color: $footer-text-color;
  font-size: em($font-size-small);
}

.layout-footer {
  text-transform: uppercase;
  padding: $vgap2 0 $gap 0;
  .list-description {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: $vgap;
    // @include min($sm) {
    //   margin-right: $gap2 - 5px;
    // }
    @include min(900px) {
      float: left;
      margin-top: $gap;
    }
  }

  .list-jpc-group {
    display: inline-block;
    margin-bottom: 0;    
    vertical-align: middle;
    @include min(900px) {
      float: right;
    }
    > li {
      padding: 0 15px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

}

/*
  Subfooter
  ========================================================================== */

.layout-subfooter {
  padding-top: $gap;
  padding-bottom: $gap;
  //color: lighten($footer-bg-color, 30%);
  background-color: lighten($footer-bg-color, 2%);
  background: linear-gradient(to right, $footer-bg-color, lighten($footer-bg-color, 2%), $footer-bg-color);


  background: linear-gradient(to right, $maroon, lighten($maroon, 2%), $maroon);


  color: $subfooter-text-color;
  
  a {
    @include link-color($subfooter-text-color, #fff, $gold);
  }
  .social-links {
    font-size: 1.2em;
   
    a {
      color: $white;
      text-transform: none;
      text-decoration: none !important;
      padding: 0.5em 1em 0 0;
      i {
        font-size: 1.3em;
        margin-right: 0.25em;
      }
      &:hover {
        color: $custard;
      }
    }

    @include max($md) {
      font-size:1.1em;
    }

    @include max($mobile-lg) { 
      margin-bottom: 1.5em;
      float: none !important;
    }

  }

  .copyright-wrapper {
    @include max($mobile-lg) {
      float: none !important;
    }
  }
  .copyright {
    display: inline-block;
    margin-right: $gap;
    margin-bottom: 0;
    // margin-top: $vgap2;
    @include max($md) {
      //margin-bottom: 5px;
      display: block;
    }
  }
  
  .va {
    display: inline-block;
    margin-left: $gap;
    // strong {
    //   color: lighten($subfooter-text-color, 5%);
    // }
    @include max($android) {
      display: block;
    }
  }
}


