//
//  JPC Variables
// --------------------------------------------------

// Place project-specific variables, colours and fonts in here : )

$img-path: '../images';

// *** Project Colours
$white:   #fff;
$charcoal: #221F1F;
$black:   $charcoal;

$maroon: #641d20;
$orange: #F5821F;
$red: #D5242B;
$copper: #B45232;
$flame: #D7572D;
$yellow: #F6BC3B;
$gold: $yellow;
$blue: #0E76BC;
$navy: #2A338F;
$green: #00A669;
$gray: #7F888E;

$copper-light: #ebd0c7;//#d9a898;
$maroon-light: mix(#d2bdbe, $flame, 20%);
$gold-light: #fdeece;
$gold-dark: darken($gold, 13%);
$custard: #fddda5;

$custard-light: #f5e8cb;

// Primary & Secondary Colours
// Make $brand-primary the same as $primary-color in _default-variables.scss
$primary-color: $maroon;
$secondary-color: $gold;

// Admin Tabs
$admin-tabs-bg: #eee;
$admin-tabs-color:  #000;
$admin-tabs-active-bg: $red;
$admin-tabs-active-color: #fff;

// Colour Themes
$jpc-themes: ( 
  'maroon': $maroon,
  'copper': $copper,
  'gold': $gold);

$jpc-backgrounds: ( 
  'maroon': $maroon-light,
  'copper': $copper-light,
  'gold': $gold-light);

$jpc-group: ( 
  'lnt': $blue,
  'iws': $red,
  'fb': $orange,
  'wfc': $green,
);

$jpc-group-secondary: ( 
  'lnt': $navy,
  'iws': $maroon,
  'fb': $red,
  'wfc': $navy,
);

//
//  Page Variables
// --------------------------------------------------
$contact-page:  7;
$team-page:     11;
$services-page: 3;
$about-page: 13;
$news-page: 5;
//$our-difference: xx;

//
//  JPC Fonts
// --------------------------------------------------

// Fonts
//@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900");
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$lato:      "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
$opensans : 'Open Sans', sans-serif;
$custom-font: $opensans;// $lato;


//@import url(//fonts.googleapis.com/css?family=Lato:400,700,900);

