//
// General node styling
// --------------------------------------------------

.node {
  position: relative;

  a.image {
    display: block;
    max-width: 100%;
    height: 100%;    
    display: block;
    position: relative;
    //background-color: $custard-light;
    .replace-as-bg {
      width: 100%;
      height: 100%;    
    }

  }
}
.node.full {
  margin-bottom: $vgap2;
  .layout-primary > .field, .field-name-body {
    margin-bottom: $vgap2;
  }

  .field-label {
    font-size: em($font-size-h3);
    //letter-spacing: -0.02em;
    margin-bottom: 10px;
    color: $black;
  }

  .field-name-body {
    ul {
      margin-bottom: $vgap2;
      list-style: none;
      padding-left: 0;
      li:not(:last-child) {
        //margin-bottom: 0.5em;
      }
      li:before {          
        content: "\25CF";
        color: $flame;
      //  content: "\25CF";
        display: inline-block;/*missing prop*/
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
    p + h2, ul + h2, p + h3, p + h4, ul + h3 {
      margin-top: $vgap3;
    }

  }

  .layout-hero {
    margin-bottom: $vgap3;
  }

  .layout-primary {
    @include min($sm) {
      padding-right: $gap4;
    }
  }

  .btn-back {
    margin-top: $vgap3;
    @include min($sm) {
      margin-bottom: $vgap3;
    }
  }

  @include uncollapsed {
    margin-bottom: $vgap4;
    .layout-hero {
      margin-bottom: $vgap6;
    }
    .layout-primary {
      padding-right: $gap4;
    }
  }
}

.node-article-full,
.node-page-full {
  @include min($kindle) {
    margin: 0 auto;
    text-align: left;
    .rte-center {
      text-align: left;
    }
  }
  @include min($md) {
    max-width: 80%;//66.66667%;
  }

}



.node:not(.node-testimonial) {
  .field-name-body > blockquote, 
  > blockquote {
    font-size: 1.7em;
    line-height: $line-height-large;
    color: $gray-darker;
  }
}



.node.teaser {
  .node-header {
    margin-top:0;
    a {
      color: $maroon;
    }
  }
}



.views-row-last .node.teaser hr {
  display: none;
}

//
// Articles / News (node-5)
// --------------------------------------------------

.page-node-#{$news-page} {
  
  @include max($md) {
    .page-header {
      margin-left: $gap;
      margin-right: $gap;
    }
  }
  .layout-featured-news {
    margin-top: 120px !important;
    background-color: $gray-lightest;
    @include max($sm) {
      margin-top: $vgap2 !important;
      background-image: linear-gradient(to bottom, white 0%, white 30px, $gray-lightest 30px, $white 50%);
    }

  }
  .layout-social {
    border-bottom: 1px solid $gray-lightest; 
    background-image: linear-gradient(to bottom, white 0%, white 70%, $gray-lightest 80%);
    border-top: 1px solid $gray-lightest;

    .facebook-widget {
      margin-bottom: $vgap3;
    }

    .container-fluid {
      @include min($lg) {
        max-width: 1170px;
      }
      @include min(1400px) {
        max-width: 1360px;
      }
    }
    @include max($sm) {
      padding-top: 0 !important;
      border-top: 1px solid $gray-light;
      #node-#{$news-page} {
        margin-top: $vgap3;
      }
    }
  }
  #node-#{$news-page} .field-name-body {

    h2 {
      @include block-title;
    }

    //font-size:1.2em;
    p:first-of-type {
      font-size:1.6em;
      color: $black;
      font-size: $font-size-h2;
      font-weight: 300;
    }

    p .label {
      min-width: 5em;  
      font-size: em($font-size-h3);
      letter-spacing: -0.02em;
      color: $maroon !important;
      margin-bottom: 2px !important;
    }

    @include min($md) {
      text-align: center;
      p.contact br {
        display: none;
      }
      p.contact .label:not(:first-of-type) {
        margin-left: $gap2;
      }
    }

  }
}

.node-article-alt_teaser,
.node-article-teaser {
  .date {
    font-size: 0.9em;
    font-weight: bold;
  }  

  .node-header a {
    color: $copper !important;
    font-weight: 600;
  }
}

.node-article-alt_teaser {
 
  .image {
    padding-top: 5px;
  }
  .node-header {
    font-size:1.4em;
  }

  .content {
    padding-bottom: 40px;
  }

  .btn-more {
    position: absolute;
    bottom: 0;
  }

  @include max($sm) {
    .image {
      margin-bottom: $vgap2;
    }
  }

}

.node-article-teaser {
  .image {
    background-color: $black;
    //border:3px solid $orange;// $gold;
  }

  .field-name-body {
    font-size: 1em;
  }
  .node-header {
    font-size:1.1em;
  }

  .more-link {
    color: $copper;
    position: relative;
    padding-left: 3px;
    font-weight: 600;
    display: block;
    i {
      position: absolute;
      margin-left: 4px;
      font-size: 12px;
      margin-top: 8px;
      color: $flame;
    }
  }

  @include max($sm) {
    .image {
      margin-bottom: $vgap2;
    }
  }


}


// .node-article-list {
//   .article-thumbnail {
//     width: 80px;
//     float: left;
//     margin-right: 10px;
//     margin-bottom: 10px;
//   }
//   .field-name-field-article-date {
//     display: inline-block;
//     margin-right: 5px;
//    // font-style: italic;
//     color: darken($maroon, 10%);
//     color: $maroon;
//     font-weight: 500;
//   }
//   .article-content {
//     &.with-thumbnail {
//       margin-left: 90px;
//     }
//     a {
//       display: block;
//     }
//   }
// }



// Awards
// --------------------------------------------------
.node-award {
  text-align: center;
  img {
    @extend .center-block;
    //min-height: 80px;
  }
}

//
// Programs
// --------------------------------------------------
.node-program-teaser {
  position: relative;
  @include max($sm) {
    .col-image {
      width: 30%;
      padding-right: $gap2;
      padding-bottom: $vgap;
      float: left;
    }
  }

  @include min($sm) {
    padding-bottom: $vgap;
    .btn-more {
      margin-top: $vgap2;
    }
  }

  .field-type-image {
    max-height: 250px;
    overflow: hidden;
    margin-top: 0.25em; //account for text-top padding
    @include min($sm) {
      //max-height: 160px;
      //height: auto;
      //height: 380px;
      //max-height: none;
    }
  }
  & + hr {
    margin: $vgap3 $gap $vgap3 $gap;
  }
}

.node-program-full{

  .when .field, .when span {
    display: inline-block;
  }

  .descrip-mobile {
    font-size: 1.25em;
  }

  .node-header {
    text-transform: none !important;
  }

  .sidebar .field-name-field-program-image {
    display: none;
  }

  .field-name-field-program-testimonial {
    margin-top: $vgap6;
  }

  @include max($sm) {
    .sidebar {
      margin-bottom: $vgap4;
    }
  }

  @include min($kindle) {
    .layout-primary .field.field-name-field-program-image {
      float: left;
      margin: 0 $gap2 $gap 0;
      max-width: 50%;
    }
  }

  @include min($sm) {
    .layout-primary .field-name-field-program-image {
      display: none;
    }
    .sidebar .field-name-field-program-image {
      display: block;
    }
  }

}

.node-program-teaser_box {
  cursor: pointer;
  overflow: hidden;
  .inner {
    position: relative;
    height:100%;
  }
  .teaserbox__body {
    padding-bottom: $vgap4;
    opacity: 0.9;
    &:hover, &:focus {
      opacity: 1;
    }
  }
  @include min($sm) {
    margin-left: $gap;
    margin-right: $gap;
    .teaserbox__image {
      height: auto;
    }
    .field-name-field-program-image {
      height: 170px;
      overflow: hidden;
    }
  }
  a.more {
    display: block;
    text-align: left;
    margin-top: $vgap;
    position: absolute;
    bottom: $vgap;
    right: $vgap;
  }
}

.layout-related-programs {
  padding-top: $vgap3;
  hr {
    margin: $vgap3 $gap;
  }
  @include min($sm) {
    padding-top: $vgap5;
    > hr {
      display: none;
    }
  }
  .h2 {
    margin-bottom: $vgap3;
  }
  .node{
    //ol col-xxs-12 col-sm-4 col-md-4
    @include make-xs-column(12);
    @include make-sm-column(4);
    margin-left:0;
    margin-right: 0;
  }
}


//
// Services
// --------------------------------------------------
.page-services #node-#{$services-page} {}

.node-service {
  .node-title {
    color: $copper;
    font-size:1.1em;
    margin-bottom: 6px;
  }
  .field-name-body {
    margin-bottom: 15px !important;
  }
  .field-name-field-icon {    
    margin: 0px auto $vgap2 0;
    img {
      max-width: 50px;
      opacity: 0.95;
      height: auto;
      transition: all 0.25s;
    }
  }

  &:hover .field-name-field-icon img {
    opacity: 1;
    transform: scale(1.05);
  }

  &.node-service--project-management-services .field-name-field-icon img {
    max-width: 70px;
  }

  @include min($sm) {
    margin-bottom:  80px !important;
  }

}


//
// Team
// --------------------------------------------------
.node-team_member-teaser {
  cursor: pointer;  

  margin-bottom: $vgap3;
  font-size: $font-size-small;
  @include min($sm) {
    margin-bottom: $vgap5;
  }
  .teaserbox__image {
    background-color: $gray-lighter; //$copper-light;
  }

  a {
    @include link-color(#fff, $active: $gold);
  }

  &.teaserbox--copper:hover .teaserbox__footer {
    background-color: $flame;
  }

  @each $key, $primary in $jpc-group {
    &.teaserbox--#{$key}.with-image {
      .teaserbox__footer {
        background-color: $primary;
      }
      &:hover .teaserbox__footer a {
        color: mix($primary, #fff, 20%);
      }
    }
    &.teaserbox--#{$key}.without-image {
      .teaserbox__footer {
        background-color: darken($primary, 10%);
      }
      &:hover .teaserbox__footer a {
        color: mix($primary, #fff, 30%);
      }
    }
  }

}


.node-team_member-full {
  @include max($sm) {
    .sidebar .field-type-image {
      margin-bottom: $vgap3;
    }
  }

  .company {
    font-weight:bold;
    font-size:1.1em;
    margin:-$vgap2 0 $vgap3 0;
    color: $maroon;
    span {
      color: $gray;
      margin:0 3px;
    }
  }

  @each $key, $primary in $jpc-group {
    &.node-team-member--#{$key} {
      $secondary: map-get($jpc-group-secondary, $key);
      .node-header {
        color: $primary;
      }
      .btn-back {
        background-color:  $primary;//$secondary;
        color: #fff;
        border-color:  $primary;//$secondary;
        &:hover, &:focus {
          background-color: darken($primary, 10%);
          border-color: darken($primary, 10%);
        }
      }
      .company--#{$key} {
        color: $primary;
      }

      aside.sidebar {
        .h3 {
          color: $primary;
        }
        .h2, .block-title {
          border-bottom:1px solid darken($primary, 5%);
        }
        hr {
          border-color: darken($primary, 5%);
        }
        @include min($sm) {
          .inner {
            background-color: $primary;
          }
          .h3 {
            color: lighten($primary, 40%);
          }
        }
      }
    }
  }

}

#node-#{$team-page} {
  display: none;
}

.page-node-#{$team-page} {
  #block-system-main {
    display: none;
  }
  .page-header {
    border:none;
  }
  .layout-above-content {
    > .block {
      @include make-xxs-column(12);
      @include make-sm-column(6);
    }
    > .block:first-child {
      @include make-sm-column-push(6);
      @include make-sm-column-push(6);
    }
    > .block:nth-child(2) {
      @include make-sm-column-pull(6);
    }
  }
}




//
// Milestones
// --------------------------------------------------
.node-milestone.full {
  margin-bottom: 0;
  margin-top: -20px;
  border-bottom: solid 1px $yellow;
  padding-bottom: 20px;

  .field-name-body {
    margin-bottom: $vgap;
  }
  h2 {
    color: $black;// $maroon;
    font-size: 1.1em;
  }
  @include min($sm) {
    margin-left: auto;
    margin-right: auto;
    max-width: 45%;
  }


}


//
// Locations
// --------------------------------------------------
.node-location {
   
  .field {
    margin-bottom: $vgap2;
  }
  .field-name-field-address .field-label {
    margin-bottom: 0 !important;
    vertical-align: top;
  }
  .field-label {
    min-width: 5em;  
    font-size: em($font-size-h3);
    letter-spacing: 0;
    color: $maroon !important;
    margin-bottom: 0px !important;
  }

  .social-links {

    a.social-icon {
      margin-top: 1rem;
      margin-right: 1rem;
      display: block;
    }

    a.social-icon .fab {
      background-color: $yellow;
      color: $white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      font-size: 20px;
      line-height: 35px;
    }

    .facebook-widget {
      margin-bottom: $vgap3;
    }

    // i {
    //   vertical-align: middle;
    //   margin-right: 0.25em;
    // }
  }

  &.full {
    margin-bottom: $vgap !important;
  }
}

.node-location-teaser {
  padding-top: 5px;
  hr {
    margin: 10px 0;
    border-top-color: $white;
    @include min($md) {
      display: none;
    }

  }


  .field-name-field-address .field-item {
    margin-left: 5em;
  }
  .node-title {
   // text-transform: uppercase;
    color: $flame;
   // letter-spacing: 0.02em;
    font-size: 1.05em;
  }
}



//
// Contact
// --------------------------------------------------
.page-node-#{$contact-page} {
  #block-system-main, #node-#{$contact-page} {
    display: none;
  }
  .layout-above-footer {
    padding-bottom: 0;
  }

  .main-content {

  }

}
#node-#{$contact-page}.node-page-full {
  @include make-row;
  .field-name-body > div {
    @include make-xxs-column(12);
    @include make-xs-column(6);
    @include make-md-column(4);
    margin-bottom: $vgap3;
  }
  .field-name-body h2,
  .field-name-body .h2  {
    @include clearfix;
    clear: both;
    padding-left: $gap;
    margin-top:$vgap4;
  }
}

//
// Testimonials
// --------------------------------------------------
.node-testimonial {
  footer {
    color: $maroon;
    font-weight: 700;
    text-align: right;
    //font-style: italic;
  }

  blockquote {
    margin:0 0 $vgap4;
    padding:0;
    border:none;
    font-weight: 300;
    font-size: $font-size-base - 1px;
    p {
      margin: 0 0 $vgap4;
      padding: 35px;
      font-size:1.1em;
      background: $custard;
      color: $gray-darker;
      line-height: 1.8;
      position: relative;
      &:after {
        content: "";
        position: absolute;

        border-style: solid;
        border-color: $custard transparent;
        display: block;
        width: 0;
        bottom: -30px;
        right: 20%;
        border-width: 30px 0 0 50px;
      }
    }
    footer {
      color: $maroon;
      font-weight: 400;
      text-align: right;
     // font-style: italic;
      font-size:1em;
      padding-right:$gap;
      &:before {
        content:"";
      }
    }
  }

}

.node-testimonial-teaser {
  margin-bottom: $vgap;
}

.node-testimonial-full {

}


//
// Home page / Front
// --------------------------------------------------
.front {

  h1.page-header {
    display: none;
  }


  .layout-middle {
    padding-top: $vgap6;
    padding-bottom: $vgap2;
    @include min($sm) {
      padding-top: $vgap6;
      padding-bottom: $vgap2;
    }
  }

  .main-content {
    text-align: center;
    .field-name-body h2 {
      line-height: 1.5;
      text-transform: none;
      color: $black;
      font-size:1.6em;
      text-align: center;
      font-weight: 400;
    }
    .field-name-body {
      margin-bottom: 0;
    }
  }
  
  .node-slide{ 
    
    &.node.full {
      margin-bottom: 0;
    }

    .first-people {
      max-width: 90%;
      height: auto;
      @include min($xs) {
        max-width: 80%;
      }
      @include min($kindle) {
        max-width: 65%;
      }
    }

    .overlay {
      background-color: $maroon;
      text-align: center;
      color: #fff;
      position: relative;
      padding: $gap;
      img {
        margin-top: $gap2;
        margin-bottom: $vgap;
      }
      .tagline {
        opacity: 1;
        font-style: italic;
        margin-left: auto;
        margin-right: auto;
        padding: $gap;
      }
    }


    
    @include min($sm) {

      /* Preserve aspect ratio */
      min-width: 100%;
      min-height: 300px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: top center;
      background-attachment: initial;
      background-repeat: no-repeat;
      width:100%;
      width:100vw;
      height:70vh;
      max-height:600px;
      position: relative;
      img.slogan {
        margin-top: $vgap3;
      }
      .field-name-field-slide-image {
        display: none;
      }
      .slogan-desktop {
        text-align: center;
        position: absolute;
        bottom: 120px;
        left: 0;
        right: 0;
        img {
          width: em(620px);
        }
      }
      .overlay {
        background-color: darken($maroon, 20%);
        opacity: 0.7;
        bottom: 0;
        position: absolute;
        left:0;
        right:0;
        padding: 0;
      }
      // img.first-people {
      //   margin-top: 10vh;
      //   //em(731px);
      // }
    }
  }

    // // Scroll arrow
  .scroll-down {
    position: absolute;
    left:0;
    right:0;
    text-align: center;
    bottom: 60px;
    z-index: $zindex-navbar - 10;
    display: none; 
    @include min($sm) {
      display: block;
    }

    .scroll-down-link {    
      color: #fff;
      transition: all 0.25s ease; 
      font-size:3em;
      line-height: 1;
      &:hover, &:focus {
        color: $gold;
        //@include transform(translateY(10px));
      }
      width:auto;
      display: inline-block;
    }
  } // scroll-down


  .layout-below-content {
    padding-top: 0;
  }

  .layout-above-footer {
    .block-title:not(.h3) {
      margin-bottom: $vgap2;
      @include min($sm) {
        margin-bottom: $vgap4;
      }
    }
  }

  .layout {
    .block-title:not(.h3) {
      margin-bottom: $vgap4;
    }
  }


}


// JPC Group
.layout-jpc-group {
  background-color: $maroon;
  color: #fff;
  h2 {
    color: #fff;
  }

  .btn-default {
    //@extend .btn--copper;
    @extend .btn--gold;
  }

  
  /*
   JPC Group List
   ========================================================================== */
  .list-jpc-group {
    margin: $vgap3 auto $vgap2;
    font-size: 1.4em;
    margin-left: -8px;
    > li {
      padding: 0 8px;
      > a {
        display: block;
        padding: 10px 15px;
      }
      img {
        // height: 25px;
        // &.iws {
        //   height: 31px;
        // }
        // &.fb {
        //   height: 28px;
        // }
      }
    }
  }

}

// Tribal Link
// .layout-triballink {
//   background-color: #000;
//   color: #fff;
//   .h2 {
//     color: #fff;
//   }
//   .row {
//     cursor: pointer;
//   }
// }


